import { UPDATE_SELECTED_TAB } from "../constants/actionType";
var initialState = {
  tabLists: ["Overview", "Portfolio", "Experience", "Work", "Skills", "Education"],
  selectedTab: "Overview"
};

var appSpecific = function appSpecific() {
  var _action$payload;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case UPDATE_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.selectedTab
      });

    default:
      return state;
  }
};

export default appSpecific;