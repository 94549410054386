var initialState = {
  overviewLists: ["A competent professional with 7+ of rich experience in UI development and Java.", "Extensive hands on experience in design and development.", "Good experience in Javascript,HTML4/5,CSS2/3,Java,Python,React,Redux,Webpack,Gulp,npm, less, Oracle SQL.", "Experience in Creating Reusable React components.", "Experience in version control system – SVN, Git.", "Having basic knowledge in Linux.", "Adaptable to change and possess good communication skills."]
};

var overview = function overview() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default overview;