var initialState = {
  portfolio: [{
    duration: "",
    projectNo: 1,
    projectName: "Reusable React Component / Library",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React"],
    description: [{
      type: "text",
      value: "Developed the Reusable React UI Components.Developed following Components. All of these components support keyboard interaction and user accessibility features"
    }, {
      type: "list",
      value: ["Date Picker", "Dropdown", "Table", "Search Box"]
    }, {
      type: "text",
      value: "All of these Reusable React UI Components are user friendly, easy to integrate in other projects and gives a great experience to the end user. Users can easily customize/change the look and feel of these components."
    }],
    link: "https://www.ganeshchandan.com/#/portfolio",
    company: null
  }],
  projects: [{
    duration: "Mar 2021 - Present",
    projectNo: 1,
    projectName: "ABI",
    client: "AB-inv",
    role: "Senior Developer ",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React"],
    description: [{
      type: "text",
      value: "Goal is to create a single application (WEB, iOS and android), allowing customers to buy ABI products through it, while turning existing salesman into business consultants (BDRs) focused in how to improve POCs. Before taking this step, several zones already had an application, but by centralizing all the shopping flow into a single application we can have some benefits, like"
    }, {
      type: "list",
      value: ["Decrease development flow from the zones", "Create a quality standard that will be the same for every zone", "Provide fast action to bugs and questions regarding the system", "Allow every zone to use a feature delivered by the product."]
    }],
    link: "",
    company: "Rightpoint India pvt Ltd."
  }, {
    duration: "Apr 2020 - Feb 2021",
    projectNo: 2,
    projectName: "GBI Invoice Report",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "Vertica", "GSF(Java)"],
    description: [{
      type: "text",
      value: "Business partners transacting AppleCare products via AppleCare Connect, either via UI or API, need a robust solution to obtain invoice reports to help with their billing reconciliation. GBI Invoice Report will provide a central location for accessing invoice reports. GBI Invoice Report Provides following features."
    }, {
      type: "list",
      value: ["Ability to generate custom ad-hoc reports using time periods, date range and/or filters on data points listed", "Ability to save custom ad-hoc reports as templates", "Downloaded reports are in Excel/CSV format", "Large reports Will be uploaded in storage and downloaded."]
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Apr 2020 - Feb 2021",
    projectNo: 3,
    projectName: "AppleCare Exchange",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "Vertica", "GSF(Java)"],
    description: [{
      type: "text",
      value: "This project is to allow AppleCare Business Development to report on the partners by regions and design attributes. This will allow the business team to view trending, statuses and other details surrounding the partner settings."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "May 2018 – Apr 2020",
    projectNo: 4,
    projectName: "Teradata Toolbox",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "Vertica", "GSF(Java)", "Oracle", "Teradata", "PostgreSQL"],
    description: [{
      type: "text",
      value: "Teradata Toolbox is a Web Application that enhances the Teradata database users' account management experience. This utility also provides useful feedback to users on their queries in realtime, its execution plan, basic alerting capabilities and also account usage details. Teradata users can access Teradata Toolbox using their AppleConnect credentials. The only supported browser for Teradata Toolbox is Apple's Safari. Users are advised to clear Safari history prior to accessing Teradata toolbox."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "May 2018 – Apr 2020",
    projectNo: 5,
    projectName: "Smart Report Tool",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "Vertica", "GSF(Java)", "Oracle", "Teradata", "PostgreSQL"],
    description: [{
      type: "text",
      value: "SmartReport is a new kind of analytics platform tool that lets end user to make informed decisions. It acts as one-stop shop where data from various databases are collected and converted into customized reports and functionality to specific data. Earlier there was no provision for a user to get visual insights directly. Excel or a dedicated UI were used in order to achieve it. This was not feasible as it consumed lot of time and resource to build dedicated UI. This issue was mitigated by introducing SmartReport Tool which provided customisable report generation directly to users. SmartReport tool is flexible enough to generate dynamic charts which could be shared across the users."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Sep 2016 – Mar 2018",
    projectNo: 6,
    projectName: "iScore Dashboard",
    client: "Apple Inc.",
    role: "Lead Engineer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "Vertica", "GSF(Java)", "Oracle"],
    description: [{
      type: "text",
      value: "iScore will provide a central location for the business to monitor metric performance, the ability to easily investigate out of spec scenarios, and implement performance/usability improvements. iScore has many  features like View pre-built/defined dashboards (daily, weekly, QBR), Ability for users to build their own highly customized dashboards, Ability to drill (down & sideways) to analyze root cause, Provide insights through annotations, Email dashboards with the ability to add context etc. iScore-Exploratory view will include a view of tabular metric data which allows the user to explore through various layers of “drill-down” aggregation or group-by options, roughly similar to the Pivot Table function in Excel. "
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Sep 2015 – Mar 2016",
    projectNo: 7,
    projectName: "CSAT Dashboard",
    client: "Apple Inc.",
    role: "Developer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "GSF(Java)", "Oracle", "Teradata"],
    description: [{
      type: "text",
      value: "CSAT allows the Users to gauge the customer satisfaction through the surveys conducted. These surveys would be made on the Service and the Support provided for various Apple Product."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Apr 2015 – Sep 2015",
    projectNo: 8,
    projectName: "iPerform",
    client: "Apple Inc.",
    role: "Developer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "React", "GSF(Java)", "Vertica"],
    description: [{
      type: "text",
      value: "A Reporting tool is used to measure the performance of the specific site and metric and staff type combination."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Sep 2014 – Apr 2015",
    projectNo: 9,
    projectName: "Snow Portal Dashboard",
    client: "Apple Inc.",
    role: "Developer",
    technologyStack: ["JavaScript", "HTML4/5", "CSS2/3", "Oracle", "Teradata"],
    description: [{
      type: "text",
      value: "The purpose of Snow Portal Dashboard  is to work as dynamic report generation dashboard with respect to the selection made on the UI screen and to view the comments given by other user, Metadata is provided from Oracle data bases to render UI screen,by using this can generate dynamic report."
    }],
    link: "",
    company: "QuEST Global"
  }, {
    duration: "Apr 2014 – Sep 2014",
    projectNo: 10,
    projectName: "OV-Tool and Bulk-load framework",
    client: "Apple Inc.",
    role: "Developer",
    technologyStack: ["python", "vertica", "Oracle", "Teradata"],
    description: [{
      type: "text",
      value: "The purpose of OV-Tool and Bulk load framework is to transfer a data from source database to target data base."
    }],
    link: "",
    company: "QuEST Global"
  }]
};

var work = function work() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default work;