import { SET_VALUES } from "./actionTypes";
export var initialState = {
  selectedValues: [],
  show: false,
  searchValue: "",
  rect: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0
  },
  autoFocusRequired: true
};

var dropdownReducer = function dropdownReducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case SET_VALUES:
      return Object.assign({}, state, payload);

    default:
      throw state;
  }
};

export default dropdownReducer;