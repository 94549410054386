var initialState = {
  name: {
    firstName: "Ganesh",
    lastName: "Chandan"
  },
  phoneNos: ["+91 7259902304"],
  emailIds: ["chandan.ganesh92@gmail.com"],
  imageUrl: [""]
};

var personInformation = function personInformation() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default personInformation;