var initialState = {
  companies: [{
    name: "Rightpoint India pvt Ltd.",
    role: "Senior Developer",
    year: "Mar 2021 - Present",
    imageUrl: "rightPoint.png"
  }, {
    name: "Quest Global pvt Ltd.",
    role: "Lead Engineer",
    year: "Nov 2013 - Feb 2021",
    imageUrl: "questGlobal.png"
  }]
};

var experience = function experience() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default experience;