var initialState = {
  skills: [{
    name: "Javascript",
    rating: 4
  }, {
    name: "HTML",
    rating: 4
  }, {
    name: "CSS",
    rating: 4
  }, {
    name: "React",
    rating: 4
  }, {
    name: "webpack",
    rating: 4
  }, {
    name: "SASS / SCSS",
    rating: 4
  }, {
    name: "JSON",
    rating: 4
  }, {
    name: "JQuery",
    rating: 3
  }, {
    name: "Java",
    rating: 3
  }, {
    name: "Python",
    rating: 3
  }, {
    name: "Oracle",
    rating: 3
  }, {
    name: "Vertica",
    rating: 3
  }, {
    name: "Teradata",
    rating: 3
  }, {
    name: "Postgresql",
    rating: 3
  }]
};

var skills = function skills() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default skills;