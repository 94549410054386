//@ts-nocheck
export var getContentHeight = function getContentHeight(props) {
  var dropDownHeader = props.dropDownHeader,
      isSerachAble = props.isSerachAble,
      multiSelect = props.multiSelect,
      clearButtonRequired = props.clearButtonRequired;
  var contentHeight = 0;

  if (dropDownHeader !== "") {
    contentHeight = contentHeight + 35;
  }

  if (isSerachAble) {
    contentHeight = contentHeight + 40;
  }

  if (multiSelect || clearButtonRequired && !multiSelect) {
    contentHeight = contentHeight + 40;
  }

  return contentHeight + 20;
};
export var getDropdownStyle = function getDropdownStyle(props, state) {
  var rect = state.rect,
      show = state.show;
  var maxWidth = props.maxWidth;
  var width = props.width,
      maxHeight = props.maxHeight;
  var top = rect.top,
      height = rect.height,
      left = rect.left;
  var style = {
    top: top + height,
    left: left,
    width: width
  };
  var totalContainerHeight = top + height + maxHeight;
  var heightValue = window.innerHeight - totalContainerHeight;
  var listItemHeight = 0;
  var arrowStyle = {};

  if (maxWidth) {
    width = maxWidth;
    style.width = width;
  }

  if (heightValue < 0) {
    maxHeight = maxHeight + heightValue;
    totalContainerHeight = top + height + maxHeight;
  }

  var contentHeight = getContentHeight(props);
  listItemHeight = maxHeight - contentHeight;
  arrowStyle = {
    left: 30,
    top: 0
  };

  if (maxHeight < contentHeight + 50) {
    maxHeight = props.maxHeight;
    heightValue = top - maxHeight;

    if (heightValue < 0) {
      maxHeight = maxHeight + heightValue;
      totalContainerHeight = top + height + maxHeight;
    }

    style = {
      bottom: window.innerHeight - top,
      left: left,
      width: props.width,
      maxHeight: maxHeight
    };
    listItemHeight = maxHeight - contentHeight;
  }

  if (window.innerWidth < left + props.width) {
    //@ts-ignore
    style.left = style.left - props.width + width;
    arrowStyle = {
      right: 30,
      top: 0
    };
  }

  style.width = width;
  style.left = style.left;
  style.maxHeight = show ? maxHeight : 0;
  return {
    style: style,
    width: width,
    maxHeight: listItemHeight,
    arrowStyle: arrowStyle
  };
};
export var currentElement = function currentElement(target, className) {
  var targetClassName = target.className ? target.className : "";

  if (targetClassName.indexOf(className) !== -1) {
    return target;
  } else {
    var nextElement = target.parentElement;
    return currentElement(nextElement, className);
  }
};
export var eventsStop = function eventsStop(event) {
  event.stopPropagation();
  event.preventDefault();
};
export var escKeyPress = function escKeyPress(show, keyCode, dispatch, selectedValues) {
  if (show && keyCode === 27) {
    dispatch({
      type: SET_VALUES,
      payload: {
        show: false,
        searchValue: "",
        selectedValues: JSON.parse(JSON.stringify(selectedValues)),
        rect: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 0,
          height: 0
        }
      }
    });
  }
}; // Tree dropdown

export var formatDropdownItems = function formatDropdownItems(items, selectedItems, serachValue) {
  var noOfElements = 0,
      avialableItems = [];
  serachValue = serachValue.trim();
  items.forEach(function (item) {
    var selectedValues = selectedItems[item.value];
    var avialableItem = JSON.parse(JSON.stringify(item));
    selectedValues = selectedValues ? selectedValues : {
      isAllValueSelected: false,
      selectedChilds: []
    };
    noOfElements++;

    if (selectedValues.isAllValueSelected) {
      selectedValues.selectedChilds = [];
    }

    if (item.hasOwnProperty("childrens")) {
      avialableItem.childrens = [];
      item.childrens.forEach(function (children) {
        if (selectedValues.isAllValueSelected) {
          selectedValues.selectedChilds.push(children.value);
        }

        if (serachValue === "" || children.name.toLowerCase().indexOf(serachValue.toLowerCase()) !== -1) {
          noOfElements++;
          avialableItem.childrens.push(Object.assign({}, children));
        }
      });
    }

    avialableItems.push(avialableItem);
  });
  return {
    noOfElements: noOfElements,
    items: avialableItems
  };
};