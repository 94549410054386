var initialState = {
  academies: [{
    degreeName: "Bachelor of Engineering",
    streamName: "Electronic and Communication Engineering",
    academyName: "Malnad College of Engineering,Hassan",
    academyYear: "Sep 2009 - Sep 2013"
  }, {
    degreeName: "Class 12",
    streamName: "Pre-university Karantaka State Board",
    academyName: "Viveka Pre-university college, Kota",
    academyYear: "Jun 2008 - Apr 2009"
  }, {
    degreeName: "Class 10",
    streamName: "Karantaka State Board",
    academyName: "Government High School, Kavadi",
    academyYear: "Jun 2006 - Apr 2007"
  }]
};

var education = function education() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    default:
      return state;
  }
};

export default education;