import { combineReducers } from "redux";
import personInformation from "./personInformation";
import appSpecific from "./appSpecific";
import overview from "./overview";
import skills from "./skills";
import work from "./work";
import experience from "./experience";
import education from "./education"; // export type AppState = ReturnType<typeof rootReducer>;

var reducer = {
  personInformation: personInformation,
  appSpecific: appSpecific,
  overview: overview,
  skills: skills,
  work: work,
  experience: experience,
  education: education
};
export default combineReducers(reducer);